import Vue from 'vue'

const api = {}

/**
 * 深度赋值属性
 *
 * @param {*} obj
 * @param {*} path
 * @param {*} value
 * @returns
 */
function property(obj, path, value) {
  if (path.indexOf('/') >= 0) {
    const list = path.split('/')
    const key = list[0]

    list.splice(0, 1)
    // eslint-disable-next-line init-declarations
    let newPath

    if (list.length <= 1) {
      newPath = list[0]
    } else {
      newPath = list.join('/')
    }
    obj[key] = obj[key] || {}
    property(obj[key], newPath, value)
  } else {
    obj[path] = value
    return obj
  }
}

const apiMethods = require.context(
  // 其filter目录的相对路径
  '../api',
  // 是否查询其子目录
  true,
  // 匹配基础filter包名
  /\w+\.js$/
)

apiMethods.keys().forEach(fileName => {
  // 获取api包名
  const apiMethod = apiMethods(fileName)
  // 剥去文件名开头的 `./` 和结尾的扩展名
  const apiName = fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
  const func = apiMethod.default || apiMethod

  property(api, apiName, func)
})

Vue.prototype.$api = api
