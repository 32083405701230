<template>
  <div class="player-head">
    <div class="player-head-logo">
      浅听·音乐
    </div>
    <player-menu />
    <div class="player-head-toolbox">
      <el-input
        v-model="searchKey"
        class="player-head-search"
        placeholder="可搜索歌单、歌手、歌曲名称"
      >
        <i
          slot="suffix"
          class="el-icon-search"
        >
        </i>
      </el-input>
      <div class="player-head-login">
        登录
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayerHeader',
  data() {
    return {
      searchKey: null
    }
  }
}
</script>

<style lang="less" scoped>
.player-head {
  z-index: 2;
  width: 100%;
  height: 60px;
  display: flex;
  padding: 0 20px;
  position: relative;
  align-items: center;
  background-color: #292a2d;
  justify-content: space-between;
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.5);

  .player-head-logo {
    color: #008dff;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
  }

  .player-head-menu {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .player-head-menu-item {
      height: 100%;
      cursor: pointer;
      padding: 0 20px;
      line-height: 60px;
      letter-spacing: 2px;

      &.active {
        color: #FFFFFF;
        background-color: #000000;
        border-bottom: 2px solid #008dff;
      }
    }
  }

  .player-head-toolbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .player-head-search {
      width: 300px;
      margin-right: 30px;
    }

    .player-head-login {
      cursor: pointer;
    }
  }
}
</style>
