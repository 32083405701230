export default [
  {
    path: '/',
    name: 'home',
    component: () => import("@/views/home")
  },
  {
    path: '/music',
    name: 'music',
    component: () => import('@/views/music/index.vue')
  },
  {
    path: "/album",
    name: "Album",
    component: () => import("@/views/album/index.vue"),
    meta: {
      belong: "/album"
    }
  },
  {
    path: "/album/:id",
    name: "AlbumDetails",
    component: () => import("@/views/album/details.vue"),
    meta: {
      belong: "/album"
    }
  },
  {
    path: "/singer",
    name: "SingerList",
    component: () => import("@/views/singer"),
    meta: {
      belong: "/singer"
    }
  }
]
