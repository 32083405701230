<template>
  <div
    class="player-video"
    :class="{'hide':hideVideo}"
  >

    <!-- Start 展开/收缩媒体菜单 -->
    <div
      class="player-video-toggle"
      @click="toggleVideoStatus"
    >
      <player-icon
        name="zhankai"
        class="player-video-toggle-icon"
      >
      </player-icon>
    </div>
    <!-- End 展开/收缩媒体菜单 -->

    <!-- Start 歌曲控制区域 -->
    <div class="player-video-ctrl">

      <!-- Start 上一首 -->
      <el-tooltip content="上一首">
        <player-icon
          name="shangyishou"
          class="player-video-ctrl-item"
          @click="changeMusicInfo(false)"
        >
        </player-icon>
      </el-tooltip>
      <!-- End 上一首 -->

      <!-- Start 播放/暂停 -->
      <el-tooltip :content="isPause ? '播放' : '暂停'">
        <player-icon
          class="player-video-ctrl-item"
          :name="isPause ? 'bofang' : 'zanting'"
          @click="togglePauseStatus"
        >
        </player-icon>
      </el-tooltip>
      <!-- End 播放/暂停 -->

      <!-- Start 下一首 -->
      <el-tooltip content="下一首">
        <player-icon
          name="xiayishou"
          class="player-video-ctrl-item"
          @click="changeMusicInfo(true)"
        >
        </player-icon>
      </el-tooltip>
      <!-- End 下一首 -->

    </div>
    <!-- End 歌曲控制区域 -->

    <!-- Start 歌曲信息展示区域 -->
    <div class="player-video-music-info">
      <!-- Start 当前播放时长 -->
      <div class="player-video-info-time current">
        {{ playerTimeFormat(currentTime) }}
      </div>
      <!-- End 当前播放时长 -->

      <!-- Start 歌曲主体信息 -->
      <div class="player-video-info-main">
        <div class="player-video-info-progress">
          <div
            :style="{'width': percent}"
            class="player-video-info-progress-current"
          >
          </div>
          <div
            draggable="false"
            :style="{'left':percent}"
            class="player-video-info-drag"
          >
          </div>
        </div>
        <div class="player-video-info-name">
          <div class="player-video-info-name-singer">
            {{ `${singerName}--${musicName}` }}
          </div>
        </div>
      </div>
      <!-- End 歌曲主体信息 -->

      <!-- Start 歌曲总时长信息 -->
      <div class="player-video-info-time total">
        {{ playerTimeFormat(totalTime) }}
      </div>
      <!-- End 歌曲总时长信息 -->

    </div>
    <!-- End 歌曲信息展示区域 -->

    <!-- Start 音量控制区域 -->
    <div class="player-video-volume">

      <!-- Start 静音状态图标 -->
      <el-tooltip :content="isMute ? '解除静音' : '静音'">
        <player-icon
          class="player-video-volume-status"
          :name="isMute ? 'jingyin' : 'yinliang'"
          @click="toggleMuteStatus"
        >
        </player-icon>
      </el-tooltip>
      <!-- End 静音状态图标 -->

      <!-- Start 音量减少 -->
      <el-tooltip content="音量减少">
        <player-icon
          name="jianshao"
          class="player-video-volume-adjust"
          @click="toggleVolume(-10)"
        >
        </player-icon>
      </el-tooltip>
      <!-- End 音量减少 -->

      <!-- Start 音量大小显示 -->
      <div class="player-video-volume-progress">
        <div
          class="player-video-volume-current"
          :style="{'width':volume + '%'}"
        >
        </div>
      </div>
      <!-- End 音量大小显示 -->

      <!-- Start 音量增加 -->
      <el-tooltip content="音量增加">
        <player-icon
          name="jia"
          class="player-video-volume-adjust plus"
          @click="toggleVolume(10)"
        >
        </player-icon>
      </el-tooltip>
      <!-- End 音量增加 -->

    </div>
    <!-- End 音量控制区域 -->

    <!-- Start 播放模式控制区域 -->
    <el-tooltip :content="typeInfo.name">
      <player-icon
        :name="typeInfo.icon"
        class="player-video-play-model"
        @click="togglePlayType"
      >
      </player-icon>
    </el-tooltip>
    <!-- End 播放模式控制区域 -->

    <!-- Start 歌曲播放清单呈现区域 -->
    <el-tooltip content="播放清单">
      <div class="player-video-music-list">
        <player-icon
          name="playList"
          class="player-video-music-list-icon"
        >
        </player-icon>
        <div class="player-video-music-num">
          {{ listNum }}
        </div>
      </div>
    </el-tooltip>
    <!-- End 歌曲播放清单呈现区域 -->
  </div>
</template>

<script>
import {playerTimeFormat} from '@/tools'

export default {
  name: 'PlayerVideo',
  data() {
    return {
      endX: 0,
      startX: 0,
      oldTime: 0,
      timer: null,
      inDrag: false,
      currentTime: 0,
      totalTime: '0',
      singerName: '',
      musicName: '',
      musicSrc: '',
      audioInfo: new Audio(),
    }
  },
  computed: {
    isPause() {
      return this.$store.audioInfo.getIsPauseStatus()
    },
    isMute() {
      return this.$store.audioInfo.getIsMuteStatus()
    },
    volume() {
      return this.$store.audioInfo.getVolumeData()
    },
    playType() {
      return this.$store.audioInfo.getPlayType()
    },
    typeInfo() {
      const list = [
        {
          name: '顺序播放',
          icon: 'shunxu'
        },
        {
          name: '随机播放',
          icon: 'suiji'
        },
        {
          name: '单曲循环',
          icon: 'danquxunhuan'
        },
        {
          name: '循环播放',
          icon: 'xunhuan'
        }
      ]
      return list[this.playType - 1]
    },
    hideVideo() {
      return this.$store.hideVideo
    },
    list() {
      return this.$store.audioInfo.getPlayList()
    },
    percent() {
      return (this.currentTime / this.totalTime * 100).toFixed(2) + '%'
    },
    musicInfo() {
      return this.$store.audioInfo.getPlayMusicInfo();
    },
    listNum() {
      return this.list?.length || 0;
    },
    musicChanged() {
      return this.$store.audioInfo.getMusicChangedStatus();
    }
  },
  watch: {
    musicInfo: {
      deep: true,
      immediate: true,
      handler(val) {
        this.singerName = val?.singerInfo?.name || '';
        this.musicName = val?.name || '';
        this.audioInfo.src = val?.url || '';
        this.currentTime = 0;
        if (this.isPause) {
          this.togglePauseStatus()
        } else {
          this.audioInfo.pause();
          this.audioInfo?.play()
        }
      }
    },
    musicChanged: {
      immediate: true,
      handler(val) {
        if (!val) {
          return;
        }
        this.changeMusicPlayTimes()
      }
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.updateChangeTime);
    window.removeEventListener('mouseup', this.endChangeTime);
    const dom = document.querySelector('.player-video-info-drag');
    dom.removeEventListener('mousedown', this.startChangeTime);
    window.removeEventListener('keydown', this.checkKeydownEvent)
  },
  methods: {
    playerTimeFormat,

    /**
     * @description: 组件初始化
     */
    init() {
      this.initKeydownEvent();
      this.watchDragIconEvent();
      this.getMusicTotalTime();
      this.getAudioCurrentTime();
      if (this.musicInfo?.id) {
        this.setCurrentTime();
        this.togglePauseStatus();
      }
    },

    /**
     * @description: 获取歌曲总时长信息
     */
    getMusicTotalTime() {
      this.audioInfo?.addEventListener('canplay', (e) => {
        this.totalTime = e?.srcElement?.duration || '0';
      })
    },

    /**
     * @description: 获取当前播放时长
     */
    getAudioCurrentTime() {
      // 拖拽中不获取当前播放时长，避免拖拽抖动
      if (!this.inDrag) {
        this.currentTime = this.audioInfo?.currentTime;
        if (this.totalTime > 0 && this.totalTime - this.currentTime < 1) {
          this.changeMusicInfo(true);
        }
      }
      setTimeout(() => {
        this.getAudioCurrentTime();
        localStorage.setItem('currentTime', this.currentTime);
      }, 100);
    },

    /**
     * @description: 设置当前播放时间点
     */
    setCurrentTime() {
      this.audioInfo.currentTime = parseFloat(localStorage.getItem('currentTime'));
    },

    /**
     * @description: 切换暂停状态
     */
    togglePauseStatus() {
      this.$store.audioInfo.setIsPauseStatus()
      if (!this.isPause) {
        this.audioInfo.volume = this.volume / 100;
        localStorage.setItem('volume', this.volume);
        this.audioInfo.currentTime = this.currentTime;
        this.audioInfo?.play();
      } else {
        this.audioInfo?.pause();
      }
    },

    /**
     * @description: 更改歌曲播放次数
     */
    async changeMusicPlayTimes() {
      const {id} = this.musicInfo;
      if (!id) {
        this.$store.audioInfo.toggleChangedStatus()
        return;
      }
      const res = await this.$api.music.updateMusicPlayTimes({id: id});
      if (res) {
        this.$store.audioInfo.toggleChangedStatus()
      }
    },

    /**
     * @description: 切换媒体控制器展开状态
     */
    toggleVideoStatus() {
      this.$store.toggleVideoShow()
    },

    /**
     * @description: 切换静音状态
     */
    toggleMuteStatus() {
      this.$store.audioInfo.setIsMuteStatus()
      this.audioInfo.muted = this.isMute;
    },

    /**
     * @description: 调节音量大小
     */
    toggleVolume(num) {
      let volume = this.volume + num
      volume = volume < 0 ? 0 : volume > 100 ? 100 : volume
      this.$store.audioInfo.setVolumeData(volume)
      this.audioInfo.volume = volume / 100;
      localStorage.setItem('volume', this.volume);
    },

    /**
     * @description: 切换播放模式
     */
    togglePlayType() {
      this.$store.audioInfo.setPlayerType()
    },

    /**
     * @description: 切换播放清单内歌曲信息
     * @param isNext 是否为下一首
     */
    changeMusicInfo(isNext) {
      const index = this.list.findIndex(item => item.id === this.musicInfo.id);
      const tIndex = isNext ? index + 1 : index - 1;
      this.$store.audioInfo.setPlayMusicInfo(this.list[tIndex] || this.list[0]);
    },

    /**
     * @description: 初始化按键事件监听
     */
    initKeydownEvent() {
      window.addEventListener('keydown', this.checkKeydownEvent)
    },

    /**
     * @description: 检测按键事件
     */
    checkKeydownEvent(e) {
      const keycode = e.keyCode;
      e.stopPropagation();
      if (keycode === 32) {
        this.togglePauseStatus();
      }
    },

    /**
     * @description: 监听拖拽按钮事件
     */
    watchDragIconEvent() {
      const dom = document.querySelector('.player-video-info-drag');
      dom.addEventListener('mousedown', this.startChangeTime);
      document.addEventListener('mousemove', this.updateChangeTime)
      document.addEventListener('mouseup', this.endChangeTime)
    },

    /**
     * @description: 执行拖拽，记录起始位置
     * @param ev 原生数据信息
     */
    startChangeTime(ev) {
      this.inDrag = true;
      const {x} = ev;
      this.startX = x;
      if (!this.isPause) {
        this.togglePauseStatus()
      }
      this.oldTime = this.currentTime;
    },

    /**
     * @description: 拖拽中调整播放时长
     */
    updateChangeTime(ev) {
      if (!this.inDrag) {
        return;
      }
      const {x} = ev;

      let diffX = x - this.startX;
      const dom = this.$el.querySelector('.player-video-info-progress');
      const width = dom.clientWidth;
      let percent = (diffX / width).toFixed(2);
      let currentTime = percent * this.totalTime + this.oldTime;
      if (currentTime < 0) {
        currentTime = 0;
      }
      if (currentTime > this.totalTime - 2) {
        currentTime = this.totalTime - 2
      }
      this.currentTime = currentTime;
    },

    /**
     * @description: 结束拖拽，开启播放
     */
    endChangeTime(ev) {
      if (!this.inDrag) {
        return;
      }
      const {x} = ev;
      this.endX = x;
      this.inDrag = false;
      this.togglePauseStatus();
    },
  }
}
</script>

<style lang="less" scoped>
.player-video {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  display: flex;
  padding: 0 10px 0;
  position: absolute;
  align-items: center;
  justify-content: flex-start;
  transition: 0.5s ease-in-out bottom;
  box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, 0.5);

  * {
    user-select: none;
  }

  &.hide {
    bottom: -82px;

    .player-video-toggle {
      .player-video-toggle-icon {
        transform: rotate(180deg);
      }
    }
  }

  .player-video-toggle {
    top: -20px;
    right: 10px;
    height: 20px;
    cursor: pointer;
    line-height: 20px;
    position: absolute;
    padding: 0 10px 0 10px;
    background-color: #292a2d;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 -2px 2px 2px rgba(0, 0, 0, 0.5);

    &:hover {
      .player-video-toggle-icon {
        color: #008dff;
        transform: rotate(180deg);
      }
    }

    .player-video-toggle-icon {
      color: #FFFFFF;
      font-size: 12px;
      transition: 0.5s ease-in-out all;
    }
  }

  .player-video-ctrl {
    display: flex;
    justify-content: flex-start;

    .player-video-ctrl-item {
      margin: 0 5px;
      font-size: 40px;
      cursor: pointer;
      border-radius: 100%;
      transition: 0.5s ease-in-out all;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.9);

      &:hover {
        color: #008dff;
        transform: rotate(360deg);
      }
    }
  }

  .player-video-music-info {
    display: flex;
    margin-left: 20px;
    width: calc(100% - 420px);
    justify-content: flex-start;

    .player-video-info-time {
      width: 50px;
      margin-top: 12px;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.9);

      &.current {
        margin-right: 6px;
      }

      &.total {
        margin-left: 10px;
      }
    }

    .player-video-info-main {
      padding: 15px 0 0 0;
      width: calc(100% - 120px);

      .player-video-info-progress {
        height: 14px;
        position: relative;
        margin: 0 5px 0 5px;
        width: calc(100% - 4px);
        background-color: #000000;
        border-radius: 10px 10px 10px;
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);

        .player-video-info-progress-current {
          top: 0;
          left: 0;
          height: 14px;
          position: absolute;
          background-color: #999999;
          border-radius: 10px 10px 10px;
        }

        .player-video-info-drag {
          top: -3px;
          width: 20px;
          height: 20px;
          cursor: move;
          margin-left: -10px;
          position: absolute;
          border-radius: 100%;
          user-select: inherit;
          border: 2px solid #008dff;
          background-color: #292a2d;
        }
      }

      .player-video-info-name {
        width: 100%;
        display: flex;
        padding: 10px 10px 0 10px;
        justify-content: flex-start;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.9);
      }
    }
  }

  .player-video-volume {
    display: flex;
    margin-left: 20px;
    justify-content: flex-start;

    .player-video-volume-status {
      cursor: pointer;

      &:hover {
        color: #008dff;
      }
    }

    .player-video-volume-adjust {
      margin: 0 3px 0 5px;
      cursor: pointer;

      &.plus {
        margin: 0 3px 0 5px;
      }

      &:hover {
        color: #008dff;
      }
    }

    .player-video-volume-progress {
      width: 100px;
      height: 14px;
      margin-left: 5px;
      position: relative;
      background-color: #000000;
      border-radius: 10px 10px 10px;
      box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);

      .player-video-volume-current {
        top: 0;
        left: 0;
        height: 14px;
        position: absolute;
        background-color: #999999;
        border-radius: 10px 10px 10px;
      }
    }
  }

  .player-video-play-model {
    margin: 0 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;

    &:hover {
      color: #008dff;
    }
  }

  .player-video-music-list {
    display: flex;
    margin-top: 4px;
    cursor: pointer;
    margin-left: 10px;
    justify-content: flex-start;

    .player-video-music-list-icon {
      font-size: 16px;
      font-weight: bold;
    }

    .player-video-music-num {
      margin-left: 3px;
      font-size: 12px;
    }

    &:hover {
      .player-video-music-list-icon {
        color: #008dff;
      }

      .player-video-music-num {
        color: #008dff;
      }
    }
  }
}
</style>
