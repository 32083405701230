import request from "axios";

export default {

  /**
   * @description: 查询专辑列表数据
   */
  queryAlbumListData(params) {
    return request.post(`/album/list`, params)
  },

  /**
   * @description: 获取专辑详情数据信息
   */
  getAlbumDetailData(params) {
    return request.post('/album/details', params)
  }
}
