import request from "axios";

export default {

  /**
   * @description: 获取歌曲列表数据
   */
  getMusicListData(params) {
    return request.post(`/music/list`, params)
  },

  /**
   * @description: 根据专辑获取歌曲列表数据
   */
  getMusicListDataByAlbum(params) {
    return request.post('/music/list/by-album', params)
  },

  /**
   * @description: 获取最新歌曲-首页用
   */
  getNewMusicListData(params) {
    return request.post(`/music/list/recent`, params)
  },

  /**
   * @description: 更新歌曲播放次数
   */
  updateMusicPlayTimes(params) {
    return request.post(`/music/play`, params, {noLoading: true})
  },

  /**
   * @description: 获取热播歌曲清单
   */
  getHotMusicListData(params) {
    return request.post(`/music/list/hot`, params)
  },
}
