<template>
  <div
    id="app"
    class="music-player"
  >
    <!-- Start 播放器头部 -->
    <player-head>
    </player-head>
    <!-- End 播放器头部 -->

    <!-- Start 播放器主体内容区域 -->
    <div
      class="music-player-main"
      :class="{'show-more': store.hideVideo}"
    >
      <router-view class="music-player-content" />
    </div>
    <!-- End 播放器主体内容区域 -->

    <!-- Start 播放器控制区域 -->
    <player-video></player-video>
    <!-- End 播放器控制区域 -->
  </div>
</template>

<script>
import Player from '@/module'
import Vue from 'vue'

export default {
  name: 'App',
  data() {
    return {
      isReady: false,
      store: new Player()
    }
  },
  created() {
    this.init()
  },
  methods: {

    /**
     * @description: 数据初始化
     */
    init() {
      this.getMusicList()
    },

    async getMusicList() {
      Vue.prototype.$store = this.store
      window.$store = this.store
    }
  }
}
</script>

<style>
</style>
