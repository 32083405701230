<template>
  <div class="player-menu">
    <div class="player-menu-list">
      <div
        v-for="(item,index) in menuList"
        :key="index"
        class="player-menu-item"
        :class="{'active': currentPath === item.path}"
        @click="changePage(item.path)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayerMenu',
  computed: {
    menuList() {
      return this.$store.menuInfo.getBaseMenuList()
    },
    currentPath() {
      return this.$route.meta?.belong || this.$route.path;
    }
  },
  methods: {

    /**
     * @description: 切换页面
     */
    changePage(path) {
      if (path === this.$route.fullPath) {
        return
      }
      this.$router.push({
        path: path
      })
    }
  }
}
</script>

<style lang="less" scoped>
.player-menu {
  z-index: 1;
  display: flex;
  justify-content: center;
  width: calc(100% - 500px);

  .player-menu-list {
    height: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .player-menu-item {
      height: 100%;
      cursor: pointer;
      padding: 0 20px;
      line-height: 60px;

      &:hover {
        color: #008dff;
      }

      &.active {
        color: #008dff;
        background-color: #000000;
      }
    }
  }
}
</style>
