import axios from "axios";

export default {

  /**
   * @description: 获取歌手数据清单
   */
  getSingerDataList(params) {
    return axios.post('/singer/list', params)
  }
}
