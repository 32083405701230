import './init'
import Vue from 'vue'
import App from './App.vue'
import './style/index.less'
import ELEMENTUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router'
import Player from "./module";

const musicStore = new Player();
Vue.use(ELEMENTUI, {size: 'small'})
Vue.config.productionTip = false

Vue.prototype.$store = musicStore;
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
