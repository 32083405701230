import { deepClone } from '@/tools'

export default class Menu {
  // 基础菜单
  baseList = []
  // 收藏歌单
  favoriteList = []

  constructor() {
    this.init()
  }

  /**
   * @description: 数据初始化
   */
  init() {
    this.setBaseMenuList()
  }

  /**
   * @description: 设置基础菜单数据
   */
  setBaseMenuList() {
    this.baseList = [
      {
        path: '/',
        name: '首页'
      },
      {
        path: '/music',
        name: '曲库中心'
      },
      {
        path: '/rank',
        name: '热门排行'
      },
      {
        path: '/album',
        name: '专辑中心'
      },
      {
        path: '/singer',
        name: '歌手中心'
      }
    ]
  }

  /**
   * @description: 获取基础菜单列表
   */
  getBaseMenuList() {
    return deepClone(this.baseList)
  }

  /**
   * @description: 设置收藏的歌单清单
   */
  setFavoriteList(list = []) {
    this.favoriteList = list
  }

  /**
   * @description: 获取收藏的歌单清单
   */
  getFavoriteList() {
    return deepClone(this.favoriteList)
  }
}
