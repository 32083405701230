import Menu from '@/module/menu'
import User from '@/module/user'
import Audio from '@/module/audio'

export default class Player {
  // 菜单
  menuInfo = new Menu()
  // 播放器
  audioInfo = new Audio()
  // 用户模块
  userInfo = new User()
  // 是否需要隐藏媒体
  hideVideo = false


  /**
   * @description: 切换媒体显示/隐藏
   */
  toggleVideoShow() {
    this.hideVideo = !this.hideVideo
  }
}
