<template>
  <i
    class="iconfont"
    :class="iconName"
    @click="iconClick"
  >
  </i>
</template>

<script>

export default {
  name: 'PlayerIcon',
  props: {
    // 图标名称
    name: {
      type: String,
      default: null
    }
  },
  computed: {
    iconName() {
      if (this.name.startsWith('icon-')) {
        return this.name
      } else {
        return 'icon-' + this.name
      }
    }
  },
  methods: {
    iconClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="less">
.player-icon {
  width: 1em;
  height: 1em;
  overflow: hidden;
  fill: currentColor;
  vertical-align: -0.1em;
  box-sizing: border-box;
}
</style>
