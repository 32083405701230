/**
 * @description: 深拷贝
 */
export const deepClone = (dataInfo) => {
  return dataInfo ? JSON.parse(JSON.stringify(dataInfo)) : null
}

/**
 * @description: 时间格式化工具
 */
export const timeFullFormat = (time, format) => {
  const d = new Date(time)
  const year = d.getFullYear()
  const month = supZero(d.getMonth() + 1)
  const date = supZero(d.getDate())
  const hour = supZero(d.getHours())
  const min = supZero(d.getMinutes())
  const sec = supZero(d.getSeconds())
  const formatList = {
    'yyyy-MM-dd': `${year}年${month}月${date}日`,
    'yyyy-MM-dd hh:mm:ss': `${year}-${month}-${date} ${hour}:${min}:${sec}`
  }
  return formatList[format] || `${year}/${month}/${date} ${hour}:${min}:${sec}`
}

/**
 * @description: 播放器时间信息格式化
 */
export const playerTimeFormat = (time) => {
  if (time?.toString()?.includes(':')) {
    return time;
  }
  const min = supZero(Math.floor(time / 60))
  const sec = supZero(time % 60)
  return `${min}:${sec}`
}

/**
 * @description: 填充数字前缀0
 */
export const supZero = (num) => {
  const n = parseInt(num, 10)
  return n > 9 ? n : `0${n}`
}
